import React, { useState } from "react";
import Input from "../utils/Input.js";
import Button from "../utils/Button.js";
import Map from "../utils/Map.js";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const handleInputChange = (inputName, inputValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, number, message } = formData;
    const messageText = `*Name:* ${name}
*Email:* ${email}
*Number:* ${number}
*Message:* ${message}`;
    const whatsappNumber = "+16465486689";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      messageText
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const address = "21-21 41st Ave #5c, Long Island City, Ny 11101";

  return (
    <>
      <section className="contact">
        <div className="container">
          <div className="contact_main">
            <div className="contact_sidebar">
              <Map address={address} />
            </div>
            <div className="contact_wrapper">
              <h2 className="heading">Contact Us</h2>
              <form className="contact_form" onSubmit={handleSubmit}>
                <Input
                  label="Name"
                  placeholder="Enter Your Name..."
                  type="text"
                  onInputChange={(inputValue) =>
                    handleInputChange("name", inputValue)
                  }
                />
                <Input
                  label="Email"
                  placeholder="Enter Your Email..."
                  type="email"
                  onInputChange={(inputValue) =>
                    handleInputChange("email", inputValue)
                  }
                />
                <Input
                  label="Number"
                  placeholder="Enter Your Number..."
                  type="text"
                  onInputChange={(inputValue) =>
                    handleInputChange("number", inputValue)
                  }
                />
                <div className="input_field">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    cols="30"
                    rows="5"
                    placeholder="Message..."
                    onChange={(e) =>
                      handleInputChange("message", e.target.value)
                    }
                  ></textarea>
                </div>
                <div className="submit_btn">
                  <Button text="Submit" type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
