import React from "react";
import { deals,categories,products } from "../api/api.js";
import * as Icons from "@tabler/icons-react";
import Button from "../utils/Button.js";
import {Link} from 'react-router-dom'
// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const Home = () => {
  const dealsBanner = {
    modules: [Navigation, Autoplay],
    spaceBetween: 0,
    speed: 800,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    navigation: {
      prevEl: ".deal_slide_btn.swiper-button-prev",
      nextEl: ".deal_slide_btn.swiper-button-next",
    },
  };

  const topProducts = {
    modules: [Navigation, Autoplay],
    spaceBetween: 0,
    speed: 800,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    navigation: {
      prevEl: ".deal_slide_btn.swiper-button-prev",
      nextEl: ".deal_slide_btn.swiper-button-next",
    },
  };
  const handleBuy = (title) => {
    const messageText = `*I Want to puchased:* ${title}`;
    const whatsappNumber = "+16465486689";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      messageText
    )}`;

    window.open(whatsappUrl, "_blank");
  }
  return (
    <>
      {/* C-TOP-DEAL */}
      <section className="top_deals_banner">
      	<div className="container">
          <div className="slider_parent">
            <Swiper {...dealsBanner} className="deals_banner">
              {deals.map((deal, key) => (
                <SwiperSlide key={key}>
                  <div className="deal_slide">
                    <figure>
                      <img src={deal.image} alt="" />
                    </figure>
                    <div className="deal_slide_content_main">
                      <div className="deal_slide_content">
                        <h2 className="deal_title">{deal.title}</h2>
                        <p className="deal_description">{deal.description}</p>
                        <Button className="deal_btn" text="shop now" onClick={(e)=> handleBuy(deal.title)}/>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="deal_slide_btn swiper-button-prev"></div>
            <div className="deal_slide_btn swiper-button-next"></div>
          </div>
        </div>
      </section>
      {/* C-CATEGORIES */}
      <section className="top_categories">
        <div className="container">
        	<h2 className="heading">top categories</h2>
          <div className="t_cat">
                {/* to={category.url} ka dynamic URL*/}
          	{
          		categories.map(function(category, key) {
          			return(
			            <Link key={key} to="/products" className="t_cat_item">
			            	<h2 className="cat_title">{category.title}</h2>
			            </Link>
          			);
          		})
          	}
          </div>
        </div>
      </section>
      {/* C-PRODUCTS */}
      <section className="top_products">
        <div className="container">
          <h2 className="heading">top products</h2>
          
            <Swiper {...topProducts} className="deals_banner">
              {products.map((product, key) => (
                <SwiperSlide key={key}>
                  <div className="product-card" key={key}>
                        <figure  className="product-image" >
                          {/*DYNAMIC LINK product.category.link*/}
                          <Link to="/categories" className="product_tag">{product.category.name}</Link>
                          <img src={product.image} alt={product.title}/>
                        </figure>
                        <h3 className="product-title">{product.title}</h3>
                        <p className="product-description">{product.description}</p>
                        <div className="product_card_footer">
                          <p className="product-price">${product.price}</p>
                          <button className="product-button" onClick={(e)=> handleBuy(product.title)}>
                            <Icons.IconShoppingBag/>
                            <span>buy now</span>
                          </button>
                        </div>
                      </div>
                </SwiperSlide>
              ))}
            </Swiper>
        </div>
      </section>
    </>
  );
};

export default Home;
