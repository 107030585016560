import React from "react";
import { Link } from "react-router-dom";
import { footer } from "../api/api";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="container">
        <div className="footer_main">
          <Link to="/" className="logo">
            <img src="/images/common/logo.svg" alt="Logo" />
          </Link>
          {footer.map((footer) => (
            <div key={footer.title}>
              <h4>{footer.title}</h4>
              <ul>
                {footer.links.map((link) => (
                  <li key={link.label}>
                    {link.url.startsWith("/") ? (
                      <Link to={link.url}>{link.label}</Link>
                    ) : (
                      <a href={link.url}>{link.label}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="social_icons">
  <div className="icon facebook">
    <figure>
      <img src="images/socials/facebook.svg" alt="" />
    </figure>
  </div>
  <div className="icon twitter">
    <figure>
      <img src="images/socials/twitter.svg" alt="" />
    </figure>
  </div>
  <div className="icon instagram">
    <figure>
      <img src="images/socials/instagram.svg" alt="" />
    </figure>
  </div>
  <div className="icon google_plus">
    <figure>
      <img src="images/socials/google_plus.svg" alt="" />
    </figure>
  </div>
  <div className="icon linkedin">
    <figure>
      <img src="images/socials/linkedin.svg" alt="" />
    </figure>
  </div>
</div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
