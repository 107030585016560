import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { navigation } from "../api/api.js";
import * as Icons from "@tabler/icons-react";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {/* C-HEADER */}
      <header>
        <div className="header_contact">
          <div className="container">
            <div className="header_contact_main">
              <div className="header_contact_flex">
                <p className="header_address">
                  <Icons.IconMapPins />
                  21-21 41st Ave #5c, Long Island City, Ny 11101
                </p>
                <p className="header_address">
                  <Icons.IconAddressBook />
                  +1 646-548-6689
                </p>
                <p className="header_address">
                  <Icons.IconMail />
                  mailto:sales@shaikhenterprises.us
                </p>
              </div>
              <a href="/">WholeSaleOnly</a>
            </div>
          </div>
        </div>
        <div className="header_top">
          <div className="container">
            <div className="header_top_main">
              {/* C-LOGO */}
              <div className="logo_wrapper">
                <Link to="/" className="logo">
                  <img src="/images/common/logo.svg" alt="Logo" />
                </Link>
              </div>
              
              <button
                className={`menu_btn ${isOpen ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <Icons.IconMenu2 />
              </button>
              <nav>
                <ul className={`navigation  ${isOpen ? "active" : ""}`}>
                  <button
                    className={`menu_btn close ${isOpen ? "active" : ""}`}
                    onClick={toggleMenu}
                  >
                    <Icons.IconX />
                  </button>
                  {navigation.map(function (nav, key) {
                    return (
                      <li key={key} onClick={toggleMenu}>
                        <NavLink to={nav.url}>{nav.title}</NavLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="header_bottom">
          <div className="container"></div>
        </div>
      </header>
    </>
  );
};

export default Header;
