import React from "react";

const About = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about_main">
            <h2 className="heading">Welcome to Shaikh Enterprises!</h2>
            <p>
              At Shaikh Enterprises, we are a dynamic company specializing in
              consumer merchandise, consumer electronics, and real estate
              businesses. With a commitment to excellence and a customer-centric
              approach, we aim to deliver superior products and services that
              enhance the lives of our valued customers.
            </p>

            <p>
              In the realm of consumer merchandise, we offer a wide range of
              high-quality products that cater to the diverse needs and
              preferences of our customers. From household essentials to trendy
              lifestyle products, we strive to provide a comprehensive selection
              that meets the demands of modern consumers. Our dedication to
              quality ensures that each product we offer undergoes rigorous
              testing and meets the highest industry standards.
            </p>

            <p>
              In the realm of consumer electronics, we embrace the ever-evolving
              world of technology and bring innovative and cutting-edge devices
              to our customers. From smartphones and tablets to home
              entertainment systems and smart gadgets, our range of consumer
              electronics is carefully curated to bring convenience,
              functionality, and enjoyment to your daily life. We partner with
              renowned brands to ensure that our customers have access to the
              latest advancements and trends in the tech industry.
            </p>

            <p>
              In addition to our consumer-focused endeavors, we also have a
              strong presence in the real estate sector. We understand that
              finding the perfect space for your business or residence is
              crucial, and we are committed to helping you discover the ideal
              property that meets your needs. Our real estate division offers a
              diverse portfolio of properties, including commercial spaces,
              residential developments, and investment opportunities. Our
              experienced team of professionals is dedicated to providing
              personalized service and ensuring a smooth and seamless experience
              throughout the buying or renting process.
            </p>

            <p>
              At Shaikh Enterprises, we believe in building long-term
              relationships with our customers and partners. We are driven by a
              passion for excellence, integrity, and innovation. Our
              customer-centric approach means that your satisfaction is at the
              forefront of everything we do. Whether you are seeking top-notch
              consumer merchandise, cutting-edge consumer electronics, or your
              dream real estate property, we are here to serve you with
              professionalism and expertise.
            </p>

            <p>
              Thank you for considering Shaikh Enterprises. We invite you to
              explore our website and discover the range of products and
              services we offer. Should you have any inquiries or require
              assistance, our friendly team is ready to help.
            </p>

            <p>
              Welcome to Shaikh Enterprises, where your satisfaction is our
              priority.
            </p>
          </div>
          <div>
            <h2 className="heading">Privacy Policy</h2>
            <div>
              <h4 className="sub_heading">Collection of Information:</h4>
              <p>
                We may collect personal information such as your name, email
                address, phone number, and other relevant details when you
                interact with our website, subscribe to our newsletters, make
                purchases, or engage with our services. We may also collect
                non-personal information, such as cookies, IP addresses, and
                browsing patterns, to enhance your website experience.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Use of Information:</h4>
              <p>
                We use the collected information to provide you with a
                personalized experience, fulfill your orders, respond to
                inquiries, improve our products and services, and communicate
                with you about updates, promotions, and relevant information. We
                may also use your information for internal research, analysis,
                and marketing purposes.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Disclosure of Information:</h4>
              <p>
                We do not sell, trade, or rent your personal information to
                third parties without your explicit consent, except as required
                by law or to fulfill our contractual obligations. We may share
                your information with trusted partners and service providers who
                assist us in delivering our products and services. These
                partners are obligated to maintain the confidentiality and
                security of your information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Security Measures:</h4>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, loss, misuse, or alteration. However,
                please note that no method of transmission over the internet or
                electronic storage is completely secure, and we cannot guarantee
                the absolute security of your information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Third-Party Websites:</h4>
              <p>
                Our website may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                the content of such websites. We encourage you to review the
                privacy policies of these third parties before providing any
                personal information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Cookies:</h4>
              <p>
                We may use cookies and similar technologies to enhance your
                browsing experience, analyze website traffic, and personalize
                content. You have the option to disable cookies through your
                browser settings, but please note that this may affect the
                functionality and usability of our website.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Children's Privacy:</h4>
              <p>
                Our website is not intended for children under the age of 13. We
                do not knowingly collect personal information from children. If
                you believe that we have inadvertently collected information
                from a child, please contact us, and we will promptly take steps
                to delete the information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Updates to the Privacy Policy:</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We encourage you
                to review this policy periodically for any updates. Continued
                use of our website or services after any changes signifies your
                acceptance of the updated Privacy Policy.
              </p>
            </div>
            <div>
              <p>
                If you have any questions, concerns, or requests regarding our
                Privacy Policy or the handling of your personal information,
                please contact us using the information provided below.
              </p>
              <p>
                Contact Information:
                <br />
                Shaikh Enterprises
                <br />
                <b>Address</b>:  21-21 41st Ave #5c, Long Island City, Ny 11101
                <br />
                <b>Email</b> : mailto:sales@shaikhenterprises.us
                <br />
                <b>Phone</b> : 646-548-6689
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
