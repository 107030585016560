// DEALS BANNER
export const deals = [{
    id: 1,
    image: '/images/deals/deal_1.webp',
    title: "Huge Savings on Top Shoe Brands! Shop Now and Step Up Your Style!",
    description: "Discover incredible discounts on top shoe brands. From sneakers to heels, find your perfect style and save big. Shop now!",
    url: "/deal1" // Replace with the desired URL for the deal
  },
  {
    id: 2,
    image: '/images/deals/deal_2.webp',
    title: "Limited Time Offer: Exclusive Deals on the Latest Shoe Trends!",
    description: "Don't miss out on exclusive deals for a limited time! Explore the latest shoe trends and enjoy special discounts. Upgrade your footwear collection today!",
    url: "/deal2" // Replace with the desired URL for the deal
  },
  {
    id: 3,
    image: '/images/deals/deal_3.webp',
    title: "Unbeatable Prices on Athletic Shoes! Get Active with Style!",
    description: "Get ready to elevate your athletic performance with style! Shop our wide selection of athletic shoes at unbeatable prices. Find the perfect pair and hit your fitness goals in style!",
    url: "/deal3" // Replace with the desired URL for the deal
  }
];
// NAVIGATION
export const navigation = [{
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 4,
    title: "categories",
    url: "/categories",
  },
  {
    id: 5,
    title: "Contact",
    url: "/contact",
  },
];

// TOP CATEGORIES
export const categories = [{
    id: 1,
    title: "Health & Beauty",
    url: "/health-beauty"
  },
  {
    id: 2,
    title: "Cleaning & Household",
    url: "/cleaning-household"
  },
  {
    id: 3,
    title: "Automotive",
    url: "/automotive"
  },
  {
    id: 4,
    title: "Baby Products",
    url: "/baby-products"
  },
  {
    id: 5,
    title: "Pet",
    url: "/pet"
  },
  {
    id: 6,
    title: "Food & Grocery",
    url: "/food-grocery"
  },
  {
    id: 7,
    title: "Electronics & Accessories",
    url: "/electronics-accessories"
  },
  {
    id: 8,
    title: "Office Products",
    url: "/office-products"
  }
];


//  FOOTER
export const footer = [
  {
    title: 'Company',
    links: [
      { label: 'About Us', url: '/about' },
      { label: 'Contact Us', url: '/contact' },
      { label: 'Careers', url: '/careers' }
    ]
  },
  {
    title: 'Products',
    links: [
      { label: 'Electronics', url: '/electronics' },
      { label: 'Home & Kitchen', url: '/home' },
      { label: 'Clothing', url: '/clothing' },
    ]
  },
];
//  TOP PRODUCTS
export const products = [{
    id: 1,
    image: 'https://img.freepik.com/free-photo/levitating-music-headphones-display_23-2149817605.jpg?w=996&t=st=1685984396~exp=1685984996~hmac=461c4b21dac9a4d01a75ea4f2c59a762bfdaf90743708604fe077de057f382fc',
    title: 'Premium Wireless Headphones',
    description: 'Experience immersive audio with our premium wireless headphones.',
    price: 19.99,
    category: {
      name: 'Electronics',
      link: '/electronics'
    }
  },
  {
    id: 2,
    image: 'https://img.freepik.com/free-photo/top-view-vanity-case-products-assortment_23-2149879867.jpg?w=996&t=st=1685984403~exp=1685985003~hmac=c47937dea6bf79b14358929db0082a653d2aaaebc55a5b9651bd52072d576ca5',
    title: 'Complete Vanity Case Set',
    description: 'Get the perfect makeup look with our complete vanity case set.',
    price: 24.99,
    category: {
      name: 'Health & Beauty',
      link: '/health-beauty'
    }
  },
  {
    id: 3,
    image: 'https://img.freepik.com/free-photo/toilet-bag-products-arrangement_23-2149764693.jpg?w=996&t=st=1685984404~exp=1685985004~hmac=bbb3ee7b4c065a9c8e68cdbbcc8ebcb37748fee38e2d753f5391221e6927acff',
    title: 'Travel-friendly Toiletry Bag',
    description: 'Stay organized on your travels with our compact and stylish toiletry bag.',
    price: 14.99,
    category: {
      name: 'Travel & Leisure',
      link: '/travel-leisure'
    }
  },
  {
    id: 4,
    image: 'https://img.freepik.com/free-photo/woman-selecting-wine-store_23-2148158550.jpg?w=996&t=st=1685984409~exp=1685985009~hmac=defc80f9c870a89933b1e9c8c803aef35a7a2e14114aee07d6b0e94dd14b69e6',
    title: 'Premium Wine Collection',
    description: 'Discover our premium wine collection and indulge in exquisite flavors.',
    price: 39.99,
    category: {
      name: 'Food & Grocery',
      link: '/food-grocery'
    }
  },
  {
    id: 5,
    image: 'https://img.freepik.com/free-photo/assortment-cosmetic-products_23-2148252075.jpg?w=996&t=st=1685984409~exp=1685985009~hmac=890dc12cb8c0b9a4001b3c6a89dc8327554a69c327798c1584e2551ad984a80e',
    title: 'Luxury Skincare Set',
    description: 'Pamper your skin with our luxurious skincare set for a radiant complexion.',
    price: 59.99,
    category: {
      name: 'Health & Beauty',
      link: '/health-beauty'
    }
  },
  {
    id: 6,
    image: 'https://img.freepik.com/free-photo/top-view-assortment-healthy-food_23-2148967549.jpg?w=996&t=st=1685984408~exp=1685985008~hmac=f6b6ed1bbdeda67903684954b5195ad62e11e0bc9b69a01f97e0c2437b67d76a',
    title: 'Healthy Snack Pack',
    description: 'Satisfy your cravings with our nutritious and delicious healthy snack pack.',
    price: 9.99,
    category: {
      name: 'Food & Grocery',
      link: '/food-grocery'
    }
  },
  {
    id: 7,
    image: 'https://img.freepik.com/free-photo/flat-lay-natural-cosmetic-products_23-2148890232.jpg?w=996&t=st=1685984407~exp=1685985007~hmac=974bcbb4eeb255b61dfb9c26aa1c1aa06683986259db23cc700d424c83c1e24a',
    title: 'Organic Beauty Essentials',
    description: 'Experience the power of nature with our organic beauty essentials collection.',
    price: 29.99,
    category: {
      name: 'Health & Beauty',
      link: '/health-beauty'
    }
  },
  {
    id: 8,
    image: 'https://img.freepik.com/free-photo/various-drinks-on-black-background_23-2148743376.jpg?w=996&t=st=1685984408~exp=1685985008~hmac=bd97e65ee526fb87ed3c370c16f67f0ae2a9ef0eb6c97408473432d9c8fb0c5e',
    title: 'Refreshing Beverage Assortment',
    description: 'Quench your thirst with our refreshing assortment of beverages for every occasion.',
    price: 12.99,
    category: {
      name: 'Food & Grocery',
      link: '/food-grocery'
    }
  }
];